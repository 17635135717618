<template>
  <div class="w-full py-5 pt-4 bg-white">
    <a href="" class="w-full">
      <img class="h-20 mx-auto" :src="Logo" alt="" />
    </a>
    <div class="flex justify-around md:justify-center space-x-2 md:space-x-10 lg:space-x-20 my-8">
      <a v-for="item in navigation" :key="item.name" :href="item.href" class="nav-bar">{{ item.name }}</a>
    </div>
  </div>
  </template>
  
  <script setup>
  import Logo from '@/assets/steady.png'
  const navigation = [
    { name: 'Notre philosophie', href: '#philosophy' },
    { name: 'Nos offres', href: '#offers' },
    { name: 'Nos services', href: '#services' },
    { name: 'Notre équipe', href: '#team' },
  ]
  </script>