<template>
      <div class="w-full flex flex-col lg:flex-row justify-center items-center lg:mt-0">
      <div class="w-full lg:w-1/2 flex flex-col justify-center items-center">
        <div class="w-full px-10 lg:w-auto lg-px-0 mt-10 lg:mt-0">
        <p class="text-base font-semibold leading-7 text-dark-silver">Notre</p>
        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Équipe</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">Une équipe d'experts.</p>
        <div class="flex items-center gap-x-6">
          <a href="https://www.linkedin.com/showcase/steady-luxe/" target="_blank" class="button-cta">Découvrez nous <span aria-hidden="true">→</span></a>
        </div>
      </div>
      </div>
      <div class="lg:w-1/2 relative md:pr-5">
        <ul role="list" class="mx-auto mt-10 lg:mt-0 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
          <a :href="person.href" target="_blank" v-for="person in people" :key="person.name" class="flex flex-col gap-6 xl:flex-row transition duration-200 hover:-translate-x-5 hover:cursor-pointer ">
            <img class="aspect-[4/5] justify-center w-52 flex-none object-cover" :src="person.imageUrl" alt="" />
            <div class="flex-auto">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
              <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
            </div>
          </a>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
  import Stephanie from '@/assets/img/Stephanie.jpeg'
  import Stephane from '@/assets/img/Stephane.jpeg'
  import Rayal from '@/assets/img/Rayal.jpeg'
  import Maya from '@/assets/img/Maya.jpeg'

  const people = [
    {
      name: 'Stéphanie',
      role: 'Manager Steady Luxe ',
      imageUrl: Stephanie,
      href: "https://www.linkedin.com/in/stéphanie-thirouin-joly-0b349549/"
    },
    {
      name: 'Stéphane',
      role: 'North America Senior Consultant',
      imageUrl: Stephane,
      href: "https://www.linkedin.com/in/stephane-barraque-30631b5/"
    },
    {
      name: 'Rayal',
      role: 'Europe Senior Consultant',
      imageUrl: Rayal,
      href: "https://www.linkedin.com/in/rayal-djerboua-87028735/"
    },
    {
      name: 'Maya',
      role: 'Business Development',
      imageUrl: Maya,
      href: "https://www.linkedin.com/in/maya-betito-225648254/"
    }
  ]
  </script>