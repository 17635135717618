<template>
  <div class="w-full flex flex-col-reverse lg:flex-row-reverse px-10 lg:items-center text-justify">
    <div class="lg:w-1/2">
      <ul role="list" class="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
        <li v-for="person in people" :key="person.name" class="flex flex-col lg:flex-row lg:gap-10 items-center lg:items-start pt-12 lg:pr-5">
          <img class="aspect-[4/5] w-52 flex-none object-cover" :src="person.imageUrl" alt="" />
          <div class="">
            <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 text-center mt-5 lg:mt-0">{{ person.name }}</h3>
            <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
            <p class="mt-6 text-base leading-7 text-gray-600">{{ person.bio }}</p>
            <ul role="list" class="mt-6 flex gap-x-6">
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="h-full lg:w-1/2 flex w-full justify-center ">
      <div class="flex flex-col justify-center my-5 lg:my-0 w-full lg:w-auto">
        <p class="text-base font-semibold leading-7 text-dark-silver">Nos</p>
        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Offres</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">Des offres Premium basées sur vos besoins.</p>
        <div class="my-5 w-full flex justify-start">
          <a href="mailto:stephanie@steadyluxe.com" class="button-cta">Contactez nous <span aria-hidden="true">→</span></a>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
const people = [
  {
    name: 'Recherche de Talents',
    imageUrl:
      'https://images.pexels.com/photos/6538855/pexels-photo-6538855.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    bio: "De part notre expérience extensive dans l’industrie du luxe et parmi les plus grands fleurons mondiaux, nous vous aidons à attirer les meilleurs experts du marché dans l’industrie."
  },
  {
    name: 'Commerce & Gestion',
    imageUrl:
      'https://images.pexels.com/photos/6538587/pexels-photo-6538587.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    bio: "Le luxe est un métier à part et dénicher la perle rare peut transformer l’expérience en boutique de vos clients. Nous vous accompagnons dans le recrutement d’une équipe talentueuse et brillante.",
  },
  {
    name: 'Hôtellerie & Restauration',
    imageUrl:
      'https://images.pexels.com/photos/6466285/pexels-photo-6466285.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    bio: "L’industrie du tourisme et de la restauration a énormément souffert ces dernières années. Nos spécialistes sont dévoués à vous trouver les talents idéaux pour vos établissements.",
  },
]
</script>