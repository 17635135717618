<template>
    <div class="w-full flex flex-col md:flex-row-reverse mt-10 lg:mt-0">
      <div class="w-full px-10 md:w-1/2 md:px-10">
          <div class="flex flex-col justify-center md:min-h-[60vh] item-start">
            <p class="text-base font-semibold leading-7 text-dark-silver">Nos</p>
            <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Services</h2>
            <p class="mt-3 text-lg leading-8 text-gray-600">Des services de qualité adaptés à vos objectifs.</p>
            <div class="mt-10 text-sm leading-7 text-gray-700">
              <OurServicesContent v-for="item in servicesContent" :key="item.title" :data="{title: item.title, content: item.content}"/>
            </div>
        </div>
      </div>
      <div class="md:w-1/2 bg-cover bg-center bg-[url('https://images.pexels.com/photos/6474588/pexels-photo-6474588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')]">
      </div>
  </div>
  </template>
  
  <script setup>

import OurServicesContent from '@/components/subComponents/TextContent.vue'
  const servicesContent = [
    {
      title: 'Chasse spécialisée.',
      content: 'Notre expérience dans le milieu du recrutement a fait que nous nous spécialisons exclusivement dans la chasse pour trouver les meilleurs profils adaptés à vos besoins du moment.'
    },
    {
      title: 'Réseau d’experts.',
      content: 'Bénéficiez de notre réseau international d’experts dans l’industrie du luxe de Monaco à Miami en passant par Paris. Des experts pour répondre à tous vos challenges humains.'
    },
    {
      title: 'Modèle au succès.',
      content: "Toutes nos offres fonctionnent sur une rémunération au succès ; Vous payer 50% de notre commission à la signature du contrat de travail et 50% à la fin de la période d'essai."
    }
  ]
  </script>