<template>
  <div class="w-full lg:flex lg:flex-row-reverse">
      <div class="lg:w-1/2 p-2 md:px-10">
          <div class="flex flex-col justify-center my-10 lg:h-[60vh] mx-10 lg:mx-0 lg:item-start">
            <p class="text-base font-semibold leading-7 text-dark-silver">Notre</p>
            <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Philosophie</h2>
            <div class="mt-10 text-sm leading-7 text-gray-700" >
                <OurPhilosophyContent v-for="item in philosophyContent" :key="item.title" :data="{title: item.title, content: item.content}"/>
            </div>
        </div>
      </div>
      <div class="lg:w-1/2 bg-cover bg-center bg-[url('https://images.pexels.com/photos/6538863/pexels-photo-6538863.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')]">
      </div>
  </div>
  </template>
  
  <script setup>
  import OurPhilosophyContent from '@/components/subComponents/TextContent.vue'
  const philosophyContent = [
    {
      title:"Une expertise de l’industrie du luxe",
      content: 'Que ce soit dans la mode, la joaillerie, l’hôtellerie et la restauration notre équipe fréquente l’univers du luxe à tous les étages depuis 20 ans. Nous comprenons intimement vos besoins et problématiques.'
    },
    {
      title: 'Un focus sur l’humain',
      content: 'Aucune industrie n’est épargnée par le manque de main d’œuvre et d’employés de qualité. Notre mission depuis plus d’une décennie est de trouver les meilleures personnes pour aider nos clients à offrir les plus belles prestations.'
    },
    {
      title: 'Un modèle transparent & efficace',
      content: 'Nous travaillons main dans la main avec vos équipes dédiées au recrutement et nous adaptons à vos process RH. Nous nous rémunérons seulement au succès pour vous donner le maximum de liberté et de tranquillité d’esprit.'
    }
  ]
  </script>